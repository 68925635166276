import React from "react"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { ContentSection } from "../components/ContentSection"
import TopSubNav from "../components/TopSubNav"
import styled from "styled-components"
import CallToActionContact from "../components/CallToActionContact"
import { formatRegularLinks } from "../hooks/useFormattedRegularLinks"

export const query = graphql`
  query LegalQuery($uid: String) {
    prismic {
      allLegals(uid: $uid) {
        edges {
          node {
            seo_page_title
            seo_page_description
            seo_h1
            top_sub_nav {
              nav_item
            }
            page_title
            main_content
            _meta {
              uid
            }
          }
        }
      }
    }
  }
`

const StyledContentSection = styled(ContentSection)`
  .inner-container {
    max-width: 900px;
  }
`

export default ({ data }) => {
  const doc = data.prismic.allLegals.edges.slice(0, 1).pop()
  if (!doc) return null
  const regularLinks = formatRegularLinks(doc.node.top_sub_nav)

  const seoTitle =
    (doc.node &&
      doc.node.seo_page_title &&
      doc.node.seo_page_title[0] &&
      doc.node.seo_page_title[0].text) ||
    (doc.node &&
      doc.node.page_title &&
      doc.node.page_title[0] &&
      doc.node.page_title[0].text) ||
    ""
  const seoDescription =
    (doc.node &&
      doc.node.seo_page_description &&
      doc.node.seo_page_description[0] &&
      doc.node.seo_page_description[0].text) ||
    ""

  return (
    <Layout>
      <SEO title={seoTitle} description={seoDescription} />
      <TopSubNav
        title={RichText.asText(doc.node.page_title)}
        regularLinks={regularLinks}
      />

      <StyledContentSection>
        <div className="inner-container">
          {doc.node.seo_h1 && RichText.render(doc.node.seo_h1)}
          {RichText.render(doc.node.main_content)}
        </div>
      </StyledContentSection>
      <CallToActionContact />
    </Layout>
  )
}
